<template>
  <div>
    <h5 class="mb-3">
      <strong>Basic</strong>
    </h5>
    <a-anchor>
      <a-anchor-link href="#" title="Basic demo" />
      <a-anchor-link href="#" title="Fixed demo" />
      <a-anchor-link href="#" title="API">
        <a-anchor-link href="#" title="Anchor Props" />
        <a-anchor-link href="#" title="Link Props" />
      </a-anchor-link>
    </a-anchor>
  </div>
</template>
<script>
export default {
  name: 'KitAntdAnchorExample',
}
</script>
